@import '../../styles/mixins/breakpoints.scss';

.link {
  .button {
    background-color: rgba(253, 253, 253, 0.2);
    border: none;

    &.accent {
      background-color: #eaf9ff;

      .buttonContent {
        color: var(--c-button-primary);

        .buttonText {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    &.default {
      .buttonContent {
        .buttonText {
          margin-left: 7px;
          font-weight: 500;
        }
      }
    }

    &:hover:not([disabled]) {
      border: none;
    }
  }
}

@include screen-xs {
  .link {
    .button {
      &.accent {
        .buttonContent {
          .buttonText {
            font-size: 12px;
          }
        }
      }
    }
  }
}
