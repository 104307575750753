@import '../../styles/mixins/breakpoints.scss';

.container {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: 30px auto 0;

  &::before {
    content: '';
    position: absolute;
    border-top: 1px solid var(--c-background-primary);
    top: 0;
    left: 20px;
    right: 20px;
  }
}

.chat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: auto;

  .button {
    background-color: rgba(253, 253, 253, 0.2);
    border: none;

    .buttonContent {
      .buttonText {
        margin-left: 7px;
        font-weight: 500;
      }
    }

    &:hover:not([disabled]) {
      border: none;
    }
  }

  .chatText {
    color: 131313;
    opacity: 0.5;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 11px;
    letter-spacing: -0.01em;
  }
}

.socials {
  .socialsList {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 11px;
    height: 18px;
    li {
      height: 100%;
    }

    li + li {
      margin-left: 20px;
    }
    .socialsListText {
      margin-left: 10px;
    }
    a {
      color: var(--c-text-primary);
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.copy {
  opacity: 0.8;
  font-size: 13px;
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--c-text-primary-alt);

  .logo {
    margin-left: 20px;
    width: 55px;
  }
}

.socials,
.copy {
  padding-top: 10px;
  padding-bottom: 10px;
}

.whiteFooter {
  background-color: #ffffff;

  .container {
    &::before {
      display: none;
    }
  }

  .logo {
    width: 55px;
  }

  .copy {
    color: var(--c-text-primary);
  }
}

@include screen-xs {
  .container,
  .chat {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .copy {
    margin-left: 0;
  }

  .chat {
    margin-right: 0;
  }

  .chatText {
    margin: 20px 0;
  }

  .buttonText {
    font-size: 12px;
  }

  .whiteFooter {
    .container {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
