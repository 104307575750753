@import '../../styles/mixins/breakpoints.scss';

.errorContainer {
  border-radius: 14px;
  color: var(--c-text-primary-alt);
  font-size: 21px;

  .calendar {
    display: block;
    margin: 0 auto 10px;
  }
}

.errorMessage {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: var(--border-radius);
  .errorMessageTitle {
  }
  .errorMessageBody {
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
  }
}

@include screen-xs {
  .errorContainer {
    font-size: 18px;
  }
}
